import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import BubbleBgHome from "../../components/Home/bubbleBgHome"
import Slogan from "../../images/media/home/slogan.svg"
import HomeMore from "./homeMore"
import TwojStyl from "../../images/ts-award.svg";
import OxyAward from "../../images/love-awardd.svg";
import Kwc from "../../images/kwc-award.svg";
import Lca from "../../images/LCA23.svg";

const HeroWrapper = styled.div`
    display:flex;
    width:100%;
    height:100vh;
    position:relative;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    color:#fff;
    overflow:hidden;

    .heroSlogan {
        position:absolute;
        right:4rem;
        bottom:4rem;
        ${({theme}) => theme.media.mobile}{ 
            bottom:23%;  
            right:50%;
            transform:translateX(50%);
            max-width:200px;
        }
    }
    .heroBottom {
        position:absolute;
        bottom:0;
        padding-bottom:3rem;
        ${({theme}) => theme.media.mobile}{ 
            padding-bottom:2rem;
        }
    }

    .heroBg {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:-1;
    }
    .text-wrapper {
        ${({theme}) => theme.media.mobile}{
            margin-bottom:2rem;
        }
    }
    h1 {
        font-family:var(--fontRedHat);
        font-size:140px;
        text-transform:uppercase;
        margin:0;
        font-weight:300;
        ${({theme}) => theme.media.laptop}{
            font-size:100px;
        }
        ${({theme}) => theme.media.tablet}{
            font-size:100px;
        }
        ${({theme}) => theme.media.mobile}{
            font-size:76px;
        }
        div {
            position:relative;

        }
        .first {
            display:inline-flex;
            // margin-right:1rem;
            
            ${({theme}) => theme.media.tablet}{
                margin-right: 0;
            }
            
        }
        .break {
            display:inline-flex;
            align-items:center;
            position:relative;
        }
        .bubble{
            position:absolute;
            width:48px;
            left:-42px;
            bottom:0;
            ${({theme}) => theme.media.tablet}{
                width:28px;
                left:-26px;
                bottom:-6px;
            }
        }
        .oxy {
            display:block;
            animation: bubble 5.2s ease-in-out infinite;
        }
        .heroBubbleText {
            font-size:28px;
            position:absolute;
            top:50%;
            left:50%;
            transform:translate3d(-50%,-50%,0);
            mix-blend-mode: normal;
            opacity.8;
            ${({theme}) => theme.media.tablet}{
                font-size:16px;
                text-align:center;
            }
            ${({theme}) => theme.media.mobile}{
                font-size:12px;
                text-align:center;
            }
            
        }
        .heroBubble {
            mix-blend-mode:screen; 
            width:100%;
            &--small {
                display: none;
                position:absolute;
                top: -20px;
                right: 0;
                width:47%;
            }          
        }
        .textBubble {
            display:flex;
            animation: bubble 5.2s ease-in-out infinite;
        }
    }
    .heroLogo {
        display: flex;
        gap: 20px;
        right:var(--pagePadding);
        box-shadow: 0 0 40px rgba(0,0,0,.2);
             ${({theme}) => theme.media.mobile}{
                position:static;
                background: white;
             }
        svg{
            width:132px;
            height:auto;
            display: block;
            box-sizing: content-box;
            opacity: .7 !important;
            ${({theme}) => theme.media.smallLaptop}{
                width:110px; 
            }
            ${({theme}) => theme.media.tablet}{
                width:86px;
            }
            ${({theme}) => theme.media.smallTablet}{
                width:100px;
            }
            ${({theme}) => theme.media.mobile}{
                width: 86px;
                background-color:white;
                opacity: 1;
            }
        }
         img {
            width:132px;
            height:auto;
            display: block;
            box-sizing: content-box;
            opacity: .8 !important;
            ${({theme}) => theme.media.smallLaptop}{
                width:110px; 
            }
            ${({theme}) => theme.media.tablet}{
                width:86px;
            }
            ${({theme}) => theme.media.smallTablet}{
                width:100px;
            }
            ${({theme}) => theme.media.mobile}{
                width: 86px;
                background-color:white;
                opacity: 1;
            }
        }
    }
    .heroSubheader {
        font-size:53px;
        margin:0;
        text-align:center;
        ${({theme}) => theme.media.laptop}{
            font-size:44px;
        }
        ${({theme}) => theme.media.tablet}{
            font-size:34px;
        }
        ${({theme}) => theme.media.mobile}{
            font-size:24px;
        }
    }

    @keyframes bubble{
        0%{
          transform:translate3d(0,8px,0) rotate(-6deg);
        }
        50%{
          transform:translate3d(0px,-8px,0) scale(1.15) rotate(0deg);
        }
        100%{
          transform:translate3d(0,8px,0) rotate(-6deg);
        }
      }
      
      
      ${({theme}) => theme.media.tablet}{
            @keyframes bubble{
                0%{
                  transform:translate3d(0,4px,0) rotate(-6deg);
                }
                50%{
                  transform:translate3d(0px,-4px,0) scale(1.1) rotate(0deg);
                }
                100%{
                  transform:translate3d(0,4px,0) rotate(-6deg);
                }
              }
        }
    
    .awardsContainer{
        position: absolute;
        display: flex;
        right: 100px;
        bottom:3rem;
        gap: 20px;

        ${({theme}) => theme.media.laptop}{
            right: 80px; 
        }

        ${({theme}) => theme.media.tablet}{
            bottom: 70px;
            right: 0;
            left: 0;
            justify-content: flex-end;
            padding: 0 10px;
        }
        ${({theme}) => theme.media.smallTablet}{
            justify-content: space-between;
            bottom:120px;
        }
        ${({theme}) => theme.media.mobile}{
            bottom:90px;
        }

    }

    .heroBottom svg{
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    // .awardsContainer_mobile{
    //     position: absolute;
    //     display: flex;
    //     right: 100px;
    //     bottom:3rem;
    //     gap: 20px;

    //     @media screen and (min-width: 768px) {
    //         justify-content: center;
    //     }

    //     ${({theme}) => theme.media.tablet}{
    //         bottom: 80px;
    //         right: 0;
    //         left: 0;
    //         justify-content: flex-end;
    //         padding: 0 10px;
    //     }
    //     ${({theme}) => theme.media.laptop}{
    //         right: 80px;
    //     }
 
    // }
    .white-img{
        background-color: rgba(255,255,255,.7);
        svg {
            opacity: 1 !important;
        }
    }

`;
const Hero = ({lang}) => {
    return (

        <HeroWrapper data-hero>
            <div className="text-wrapper">
            <h1><div className="first">
                { lang && lang === "EN" ? 'B': 'O'}
            </div><span className="break">
                <div className="bubble">
                    <div className="heroBubbleText"><span className="oxy">2</span></div>
                    <div className="heroBubble">
                        <StaticImage
                        src="../../images/media/home/bubble.png"
                        loading="eager"
                        // width={110}
                        quality={85}
                        placeholder="blurred"
                        className="textBubble"
                        formats={["auto", "webp", "avif"]}
                        alt=" "
                        />
                    </div>
                    <div className="heroBubble heroBubble--small">
                        <StaticImage
                        src="../../images/media/home/bubble.png"
                        loading="eager"
                        // width={47}
                        quality={85}
                        placeholder="blurred"
                        className="textBubble"
                        formats={["auto", "webp", "avif"]}
                        alt=" "
                        />
                    </div>
                </div>
                { lang && lang === "EN" ? 'reath': 'ddech'}
            </span></h1>
            <p className="heroSubheader">{ lang && lang === "EN" ? 'for Your skin': 'dla Twojej skóry.'}</p>
            </div>

            <div className="awardsContainer">
                <div className="heroLogo"><Kwc /></div>
                <div className="heroLogo white-img"><Lca/></div>
                <div className="heroLogo"><StaticImage src="../../images/ts-award.png" width={132} height={132} alt="Twój styl 2022 nagroda" /></div>
            </div>

            {/* <div className="awardsContainer_mobile">
                <div className="heroLogo"><Kwc /> </div>
                <StaticImage class="white-img" src="../../images/love-award.png" width={86} height={86} alt="Love Cosmetics Award" />
                <StaticImage src="../../images/ts-award.png" width={86} height={86} alt="Twój styl 2022 nagroda" />
            </div> */}
           
                
              <StaticImage
                src="../../images/media/home/hero.png"
                loading="eager"
                width={1920}
                quality={85}
                placeholder="blurred"
                className="heroBg"
                formats={["auto", "webp", "avif"]}
                alt="Oxygenetic"
                />

                <div className="heroBottom">
                     <HomeMore url="/#produkty" text={ lang && lang === "EN" ? 'See the products': 'Poznaj produkty'}/>
                </div>
                <BubbleBgHome/>

        </HeroWrapper>
    )
}

export default Hero