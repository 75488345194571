import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentPage from "../layouts/contentPage"
import Hero from "../components/Home/hero"
import HomeOxygenetic from "../components/Home/homeOxygenetic"
import Brand from "../components/Home/brand"
import CategoryBox from "../components/categoryBox"
import ProducstList from "../components/productsList"

const IndexPage = () => (
  <Layout headerType="transparent" page="home">
    <Seo title="Strona Główna" />
      <Hero/>
      <ProducstList id="produkty">
        <CategoryBox className="energyze" name="Energyze" color="lime" bgtext="WIT.C" url="/produkty/energyze"/>
        <CategoryBox className="relift" name="Relift" color="magenta" bgtext="WIT.A" url="/produkty/relift"/>
        <CategoryBox className="acid" name="Acid Peel" color="blue" bgtext="AHA" bgtext2="PHA" url="/produkty/acid-peel"/>
      </ProducstList>
      <HomeOxygenetic/>
      <Brand/>
  </Layout>
)

export default IndexPage
